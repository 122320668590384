import Vue from 'vue'

Vue.prototype.$getPlatformFullName = value => {
  let platformName = ''
  switch (value) {
    case 'AC':
      platformName = 'Assetto Corsa'
      break
    case 'AMS':
      platformName = 'Automobilista'
      break
    case 'rF2':
      platformName = 'rFactor 2'
      break
    case 'ACC':
      platformName = 'Assetto Corsa Competizione'
      break
    case 'AMS2':
      platformName = 'Automobilista2'
      break
    default:
      platformName = '其它平台'
      break
  }
  return platformName
}

Vue.prototype.$getSeasonName = value => {
  let seasonResult = ''
  switch (value) {
    case 'Spring':
      seasonResult = '春季联赛'
      break
    case 'Summer':
      seasonResult = '夏季联赛'
      break
    case 'Autumn':
      seasonResult = '秋季联赛'
      break
    case 'Winter':
      seasonResult = '冬季联赛'
      break
    default:
      seasonResult = '全年联赛'
      break
  }
  return seasonResult
}

Vue.prototype.$getSeasonStatus = value => {
  let status = ''
  if (value === 1) {
    status = '赛季已结束'
  } else {
    status = '赛季进行中'
  }
  return status
}

Vue.prototype.$getRaceMode = value => {
  let mode = ''
  if (value === 0) {
    mode = '个人赛'
  } else {
    mode = '车队制'
  }
  return mode
}

Vue.prototype.$convertToLapTime = value => {
  let time = ''
  if (value < 60) {
    time = value
  } else if (value >= 60 && value < 999999) {
    const min = parseInt(value / 60, 10)
    const second = value - min * 60
    if (parseInt(value - min * 60, 10) < 10) {
      time = `${min}:0${second.toFixed(3)}`
    } else {
      time = `${min}:${second.toFixed(3)}`
    }
  } else {
    time = ''
  }
  return time
}

Vue.prototype.$getExpAvatar = value => {
  const level = Math.floor(value / 100) + 1
  let variant = ''
  if (level < 5) {
    variant = 'light-dark'
  } else if (level < 15 && level >= 5) {
    variant = 'light-info'
  } else if (level < 30 && level >= 15) {
    variant = 'light-warning'
  } else if (level < 46 && level >= 30) {
    variant = 'light-success'
  } else {
    variant = 'light-danger'
  }
  return variant
}

Vue.prototype.$gatPlatformFileName = value => {
  let file = ''
  switch (value) {
    case 'AC':
      file = 'ac'
      break
    case 'RF2':
      file = 'rf2'
      break
    case 'AMS':
      file = 'ams'
      break
    case 'AMS2':
      file = 'ams2'
      break
    case 'GSC':
      file = 'gsc'
      break
    case 'F1 2018':
      file = 'f1'
      break
    case 'ACC':
      file = 'acc'
      break
    default:
      file = 'f1'
      break
  }
  return file
}
