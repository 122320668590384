import Vue from 'vue'

Vue.prototype.$Api_Dashboard_Count = '/dashboard/count/'
Vue.prototype.$Api_Dashboard_DateUpdate = 'dashboard/dateupdate/'
Vue.prototype.$Api_Dashboard_LatestRaces = '/dashboard/latestraces?q='

Vue.prototype.$Api_Series_All = '/series/all/'
Vue.prototype.$Api_Series_Info = '/series/info?sid='
Vue.prototype.$Api_Series_RaceNum = '/series/RaceNum?sid='
Vue.prototype.$Api_Series_FinishRaceNum = '/series/FinishRaceNum?sid='
Vue.prototype.$Api_Series_PointNum = '/series/PointNum?sid='
Vue.prototype.$Api_Series_PodiumNum = '/series/PodiumNum?sid='
Vue.prototype.$Api_Series_DriversPerStage = '/series/DriversPerStage?sid='
Vue.prototype.$Api_Series_AllStages = '/series/AllStages?sid='
Vue.prototype.$Api_Series_Standings = '/series/resultdetail/driver?sid='
Vue.prototype.$Api_Series_TeamStandings = '/series/resultdetail/team?sid='

Vue.prototype.$Api_Drivers_All = '/driver/list/'
Vue.prototype.$Api_Driver_Info = '/driver/info?did='
Vue.prototype.$Api_Driver_Races = '/driver/races?did='
Vue.prototype.$Api_Driver_LastRace = '/driver/last-race?did='
Vue.prototype.$Api_Driver_Demerits = '/driver/demerit-history?did='
Vue.prototype.$Api_Driver_License_PRO = '/driver/license/pro/'
Vue.prototype.$Api_Driver_License_A = '/driver/license/a/'
Vue.prototype.$Api_Driver_License_B = '/driver/license/b/'
Vue.prototype.$Api_Driver_License_C = '/driver/license/c/'
Vue.prototype.$Api_Driver_License_D = '/driver/license/d/'
Vue.prototype.$Api_Driver_License_R = '/driver/license/r/'

Vue.prototype.$Api_Demerits_All = '/demerits/all/'

Vue.prototype.$Api_Tracks_All = '/tracks/all/'
Vue.prototype.$Api_Track_Info = '/track/info?tid='
Vue.prototype.$Api_Track_Records = '/track/records?tid='
Vue.prototype.$Api_Track_History = '/track/history?tid='

Vue.prototype.$Api_Races_All = '/races/all/'
Vue.prototype.$Api_Race_Stat = '/race/stat?rid='
Vue.prototype.$Api_Race_Info = '/race/info?rid='
Vue.prototype.$Api_Race_Series = '/race/series?rid='
Vue.prototype.$Api_Race_Details = '/race/details?rid='
Vue.prototype.$Api_Race_Property = '/race/property?rid='

Vue.prototype.$Api_Rank_Rating = '/rank/rating?q='
