import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import('@/views/Dashboard/Dashboard.vue'),
    },
    {
      path: '/series',
      name: 'series',
      component: () => import('@/views/Series/Series.vue'),
      meta: {
        pageTitle: '所有联赛',
        breadcrumb: [
          {
            text: '所有联赛',
            active: true,
          },
        ],
      },
    },
    {
      path: '/series/details/:sid',
      name: 'series-details',
      component: () => import('@/views/Series/SeriesDetails.vue'),
      meta: {
        pageTitle: '联赛详情',
        breadcrumb: [
          {
            text: '联赛详情',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/series-intro',
    //   name: 'series-intro',
    //   component: () => import('@/views/Series/SeriesIntro.vue'),
    //   meta: {
    //     pageTitle: '联赛介绍',
    //     breadcrumb: [
    //       {
    //         text: '联赛介绍',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: '/races',
      name: 'races',
      component: () => import('@/views/Race/races.vue'),
      meta: {
        pageTitle: '所有比赛',
        breadcrumb: [
          {
            text: '所有比赛',
            active: true,
          },
        ],
      },
    },
    {
      path: '/race/:rid',
      name: 'race',
      component: () => import('@/views/Race/raceDetails.vue'),
      meta: {
        pageTitle: '比赛详情',
        breadcrumb: [
          {
            text: '比赛详情',
            active: true,
          },
        ],
      },
    },
    {
      path: '/drivers',
      name: 'drivers',
      component: () => import('@/views/Driver/drivers.vue'),
      meta: {
        pageTitle: '所有车手',
        breadcrumb: [
          {
            text: '所有车手',
            active: true,
          },
        ],
      },
    },
    {
      path: '/driver/:did',
      name: 'driver',
      component: () => import('@/views/Driver/driverDetails.vue'),
      meta: {
        pageTitle: '车手详情',
        breadcrumb: [
          {
            text: '车手详情',
            active: true,
          },
        ],
      },
    },
    {
      path: '/drivers/license',
      name: 'driver-license',
      component: () => import('@/views/Driver/driverLicense.vue'),
      meta: {
        pageTitle: '赛照查询',
        breadcrumb: [
          {
            text: '赛照查询',
            active: true,
          },
        ],
      },
    },
    {
      path: '/demerits',
      name: 'demerits',
      component: () => import('@/views/Demerits/Demerits.vue'),
      meta: {
        pageTitle: '仲裁历史',
        breadcrumb: [
          {
            text: '仲裁历史',
            active: true,
          },
        ],
      },
    },
    {
      path: '/tracks',
      name: 'tracks',
      component: () => import('@/views/Track/tracks.vue'),
      meta: {
        pageTitle: '所有赛道',
        breadcrumb: [
          {
            text: '所有赛道',
            active: true,
          },
        ],
      },
    },
    {
      path: '/track/:tid',
      name: 'track',
      component: () => import('@/views/Track/trackDetails.vue'),
      meta: {
        pageTitle: '赛道详情',
        breadcrumb: [
          {
            text: '赛道详情',
            active: true,
          },
        ],
      },
    },
    {
      path: '/about/algo',
      name: 'algo',
      component: () => import('@/views/About/algo.vue'),
      meta: {
        pageTitle: '赛照与等级说明',
        breadcrumb: [
          {
            text: '赛照与等级说明',
            active: true,
          },
        ],
      },
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('@/views/About/about.vue'),
      meta: {
        pageTitle: '关于',
        breadcrumb: [
          {
            text: '关于',
            active: true,
          },
        ],
      },
    },
    {
      path: '/about/history',
      name: 'history',
      component: () => import('@/views/About/history.vue'),
      meta: {
        pageTitle: '更新历史',
        breadcrumb: [
          {
            text: '更新历史',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

export default router
